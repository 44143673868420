.dialog {
  background: #f5f5f5;
  box-shadow: 8px 8px 12px 1px rgb(91, 87, 87);
  -webkit-backdrop-filter: blur(1rem);
  padding: 20px;
  border-radius: 0.7rem;
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 80vw;
  z-index: 2;
}

.background {
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.heading {
  /* background: red; */
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
