.snack {
  background: #f5f5f5;
  box-shadow: 8px 8px 12px 1px rgb(91, 87, 87);
  -webkit-backdrop-filter: blur(1rem);
  padding: 10px;
  position: fixed;
  bottom: 2%;
  left: 50%;
  transform: translate(-50%, 0%);
  border-radius: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
